<div class="op-draggable-autocomplete">
  <div
    class="op-draggable-autocomplete--selected"
    dragula="columns"
    [(dragulaModel)]="selected"
  >
    <div class="op-draggable-autocomplete--item" *ngFor="let item of selected">
      <span
        class="op-draggable-autocomplete--item-text"
        [textContent]="item.name"
      ></span>
      <a
        (click)="remove(item)"
        class="op-draggable-autocomplete--remove-item icon-remove"
      ></a>
    </div>
  </div>

  <ng-select
    #ngSelectComponent
    [items]="availableOptions"
    ngClass="op-draggable-autocomplete--input"
    bindLabel="name"
    [multiple]="false"
    [virtualScroll]="true"
    appendTo="body"
    [placeholder]="text.placeholder"
    [clearSearchOnAdd]="true"
    [closeOnSelect]="true"
    [searchFn]="searchFunction"
    (open)="opened()"
    (change)="select($event)"
  ></ng-select>
</div>
